import { supabase } from '../supabase/supabaseClient';
import { toast } from 'react-toastify';

export interface PaymentRecord {
  user_id: any;
  event_id: any;
  ticket_id: string;
  poll_url: string;
  use_points: boolean;
  ticket_price: number;
  admission_count: number;
  payment_success: boolean;
  service_fee_amount: number;
  payment_summary_url: string;
}

export class PaymentRecordsService {
  async createEventTicketPayment(paymentData: PaymentRecord) {
    if (!paymentData || typeof paymentData !== 'object') {
      console.error('Invalid payment data:', paymentData);
      toast.error('Invalid payment data provided');
      return null;
    }

    try {
      const { data, error } = await supabase
        .from('event_ticket_payments')
        .insert([paymentData])
        .select()
        .single();

      if (error) {
        console.error('Error creating payment:', error);
        toast.error('Failed to create payment record');
        return null;
      }

      toast.success('Payment record created successfully');
      return data;

    } catch (error) {
      console.error('Error in createEventTicketPayment:', error);
      return null;
    }
  }

  async updatePaymentStatus(id: string) {
    try {
      const { data: ticketOrderData, error: ticketOrderError } = await supabase
        .from('event_ticket_payments')
        .select('*')
        .eq('id', id)
        .single();

      if (ticketOrderError) {
        console.error('Error fetching ticket order:', ticketOrderError);
        return null;
      }

      if (ticketOrderData) {
        const { data: updateOrderData, error: updateOrderError } = await supabase
          .from('event_ticket_payments')
          .update({ payment_success: true })
          .eq('id', id)
          .select();

        if (updateOrderError) {
          console.error('Error updating ticket order:', updateOrderError);
          return null;
        }
        return updateOrderData;
      } else {
        const { data: updateData, error: updateError } = await supabase
          .from('event_ticket_payments')
          .update({ payment_success: true })
          .eq('id', id)
          .select();

        if (updateError) {
          console.error('Error updating payment status:', updateError);
          return null;
        }
        return updateData;
      }
    } catch (error) {
      console.error('Error in updatePaymentStatus:', error);
      return null;
    }
  }

  async fetchAllPaymentRecords(page = 1, limit = 10) {
    try {
      const { data, error } = await supabase
        .from('event_ticket_payments')
        .select('*')
        .range((page - 1) * limit, page * limit - 1);

      if (error) {
        console.error('Error fetching payment records:', error);
        return null;
      }
      return data;

    } catch (error) {
      console.error('Error in fetchAllPaymentRecords:', error);
      return null;
    }
  }

  async getPaymentRecordsByUser(userId: string) {
    try {
      const { data, error } = await supabase
        .from('event_ticket_payments')
        .select('*')
        .eq('user_id', userId);

      if (error) {
        console.error('Error fetching payment records:', error);
        return null;
      }
      return data;

    } catch (error) {
      console.error('Error in getPaymentRecordsByUser:', error);
      return null;
    }
  }

  async getPaymentById(id: string) {
    try {
      const { data, error } = await supabase
        .from('event_ticket_payments')
        .select('*')
        .eq('id', id)
        .single();

      if (error) {
        console.error('Error fetching payment:', error);
        return null;
      }

      return data;
    } catch (error) {
      console.error('Error in getPaymentById:', error);
      return null;
    }
  }
}

export const paymentRecordsService = new PaymentRecordsService(); 
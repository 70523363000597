import React from "react";
import { useNavigate } from "react-router-dom";
import eventImage from "../../assets/images/create an events banner that fits this section.svg";
import arrow from "../../assets/images/arrow_outward.svg";

const CreateEventCTA: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex flex-col md:flex-row items-center bg-[#0B5B5C] text-white max-w-7xl min-w-7xl mx-auto my-8 rounded-md overflow-hidden">
        <div className="w-full md:w-full">
          <img src={eventImage} alt="Event" className="w-full h-full object-cover" />
        </div>
        <div className="w-full md:w-full p-8 flex flex-col justify-center items-start">
          <h2 className="text-2xl font-bold mb-4">Turn Your Ideas Into Unforgettable Events!</h2>
          <button 
            onClick={() => navigate('/organizers')}
            className={`bg-[#FCFCFC] text-[#2F2E2E] rounded-none px-2 py-2 font-bold mt-4 md:mt-0`}
          >
            Create Your Event
            <img src={arrow} alt="Arrow" className="inline ml-2 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateEventCTA;

export interface StoredAnswers {
  mandatory: Record<string, string>;
  custom: Record<string, string>;
}

export class AnswerStorageService {
  private readonly STORAGE_KEY_MANDATORY = 'event_mandatory_answers';
  private readonly STORAGE_KEY_CUSTOM = 'event_custom_answers';

  saveAnswers(answers: StoredAnswers): void {
    localStorage.setItem(this.STORAGE_KEY_MANDATORY, JSON.stringify(answers.mandatory));
    localStorage.setItem(this.STORAGE_KEY_CUSTOM, JSON.stringify(answers.custom));
  }

  loadAnswers(): StoredAnswers {
    const mandatory = localStorage.getItem(this.STORAGE_KEY_MANDATORY);
    const custom = localStorage.getItem(this.STORAGE_KEY_CUSTOM);

    return {
      mandatory: mandatory ? JSON.parse(mandatory) : {},
      custom: custom ? JSON.parse(custom) : {}
    };
  }

  clearAnswers(): void {
    localStorage.removeItem(this.STORAGE_KEY_MANDATORY);
    localStorage.removeItem(this.STORAGE_KEY_CUSTOM);
  }

  saveMandatoryAnswer(questionId: string, answer: string): void {
    const current = this.loadAnswers().mandatory;
    const updated = { ...current, [questionId]: answer };
    localStorage.setItem(this.STORAGE_KEY_MANDATORY, JSON.stringify(updated));
  }

  saveCustomAnswer(questionId: string, answer: string): void {
    const current = this.loadAnswers().custom;
    const updated = { ...current, [questionId]: answer };
    localStorage.setItem(this.STORAGE_KEY_CUSTOM, JSON.stringify(updated));
  }
}

export const answerStorageService = new AnswerStorageService(); 
import React, { useEffect, useState } from "react";
import { Event, eventsService } from "../../Api/services/eventsService";
import rectangle from "../../assets/images/Rectangle.svg";
import { EventsCard } from "./EventsCard";
import { IoMdArrowDropright } from "react-icons/io";
import Pagination from "react-js-pagination";
import { notificationService } from '../../utils/notificationService';
import { useLocation } from "react-router-dom";

export const Events: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const itemsPerPage = 10;
  const categories = [
    "Science & Technology",
    "Auto, Boat & Air",
    "Arts & Culture",
    "Music",
    "Film & Media",
    "Food & Drink",
    "Knowledge & Education",
    "Sports",
    "Travel & Tourism",
    "Religion & Spirituality",
    "Community & Social",
    "Health & Wellness",
    "Farming & Agriculture",
    "Business",
  ];
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();
  const [pendingCategories, setPendingCategories] = useState<string[]>([]);
  const [pendingStartDate, setPendingStartDate] = useState("");
  const [pendingEndDate, setPendingEndDate] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [openSections, setOpenSections] = useState({
    allEvents: true,
    categories: true,
    dateRange: false
  });

  useEffect(() => {
    // Get search query from URL
    const params = new URLSearchParams(location.search);
    const search = params.get("search");
    if (search) {
      setSearchQuery(search);
    }
  }, [location]);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const data = await eventsService.getAllEvents(
          currentPage,
          itemsPerPage,
          searchQuery,
          selectedCategories,
          startDate ? new Date(startDate).toISOString() : undefined,
          endDate ? new Date(endDate).toISOString() : undefined
        );
        setEvents(data.data);
        const total = await eventsService.getTotalItems();
        setTotalItems(total);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : "An error occurred";
        setError(errorMessage);
        notificationService.error(`Failed to load events: ${errorMessage}`);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [currentPage, startDate, endDate, selectedCategories, searchQuery]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCategoryChange = (category: string) => {
    if (isMobile) {
      setPendingCategories((prev) => {
        return prev.includes(category)
          ? prev.filter((c) => c !== category)
          : [...prev, category];
      });
    } else {
      setSelectedCategories((prev) => {
        const newCategories = prev.includes(category)
          ? prev.filter((c) => c !== category)
          : [...prev, category];
        return newCategories;
      });
    }
  };

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
    console.log(`Active page is ${pageNumber}`);
  };

  const handleDateRangeApply = () => {
    if (startDate && endDate) {
      if (new Date(startDate) > new Date(endDate)) {
        notificationService.error("Start date cannot be after end date");
        return;
      }
      notificationService.success("Date range filter applied");
    } else if (!startDate && !endDate) {
      notificationService.info("Date range filter cleared");
    } else {
      notificationService.warning("Please select both start and end dates");
    }
  };

  const applyMobileFilters = () => {
    if (pendingStartDate && pendingEndDate) {
      if (new Date(pendingStartDate) > new Date(pendingEndDate)) {
        notificationService.error("Start date cannot be after end date");
        return;
      }
    }

    setSelectedCategories(pendingCategories);
    setStartDate(pendingStartDate);
    setEndDate(pendingEndDate);
    notificationService.success("Filters applied successfully");
  };

  const resetFilters = () => {
    setSelectedCategories([]);
    setStartDate("");
    setEndDate("");
    setPendingCategories([]);
    setPendingStartDate("");
    setPendingEndDate("");
    notificationService.info("All filters have been reset");
  };

  const hasAnyFilters = () => {
    if (isMobile) {
      return (
        pendingCategories.length > 0 ||
        pendingStartDate ||
        pendingEndDate ||
        selectedCategories.length > 0 ||
        startDate ||
        endDate
      );
    }
    return selectedCategories.length > 0 || startDate || endDate;
  };

  const NoEventsPlaceholder = () => (
    <div className="col-span-full flex flex-col items-center justify-center p-8 text-center">
      <img
        src={rectangle}
        alt="No events"
        className="w-32 h-32 mb-4 opacity-50"
      />
      <h3 className="text-xl font-semibold text-gray-600 mb-2">
        No Events Found
      </h3>
      <p className="text-gray-500 mb-4">
        {selectedCategories.length > 0 || startDate || endDate
          ? "Try adjusting your filters to see more events"
          : "Check back later for upcoming events"}
      </p>
      {(selectedCategories.length > 0 || startDate || endDate) && (
        <button
          onClick={resetFilters}
          className="px-4 py-2 bg-[#0B5B5C] text-white rounded hover:bg-[#094748] transition duration-200"
        >
          Reset All Filters
        </button>
      )}
    </div>
  );

  const LoadingSkeleton = () => (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
        <div
          key={item}
          className="bg-white rounded-lg overflow-hidden shadow-sm"
        >
          {/* Image skeleton */}
          <div className="w-full h-48 bg-gray-200 animate-shimmer"></div>
          {/* Content skeleton */}
          <div className="p-4">
            <div className="h-4 bg-gray-200 rounded w-3/4 mb-2 animate-shimmer"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2 mb-4 animate-shimmer"></div>
            <div className="h-4 bg-gray-200 rounded w-full animate-shimmer"></div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderMobileApplyButton = () => {
    const hasChanges =
      pendingCategories.length > 0 || pendingStartDate || pendingEndDate;

    return isMobile && (hasChanges || hasAnyFilters()) ? (
      <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-lg z-50">
        <div className="flex gap-2">
          {hasAnyFilters() && (
            <button
              onClick={resetFilters}
              className="w-1/3 border-2 border-[#0B5B5C] text-[#0B5B5C] py-3 rounded-lg font-semibold"
            >
              Reset All
            </button>
          )}
          {hasChanges && (
            <button
              onClick={applyMobileFilters}
              className={`${
                hasAnyFilters() ? "w-2/3" : "w-full"
              } bg-[#0B5B5C] text-white py-3 rounded-lg font-semibold`}
            >
              Apply Filters
            </button>
          )}
        </div>
      </div>
    ) : null;
  };

  const toggleSection = (section: keyof typeof openSections) => {
    setOpenSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  return (
    <div className="bg-zinc-50  grid grid-cols-1 md:grid-cols-4 max-w-7xl min-w-7xl mx-auto w-full">
      <div className="p-4  rounded-lg  w-full">
        <h2 className="text-sm text-left font-semibold text-[#0B5B5C] uppercase mb-2">
          Filter by
        </h2>
        <h2 className="text-xl font-bold text-[#505050] mb-4">
          From A to Z: Every Event Imaginable
        </h2>
        <div>
          {/* All Events Section */}
          <div className="mb-4">
            {/* <div 
              className="flex items-center gap-2 mb-2 cursor-pointer"
              onClick={() => toggleSection('allEvents')}
            >
              <IoMdArrowDropright 
                className={`text-[#0B5B5C] transition-transform ${
                  openSections.allEvents ? 'rotate-90' : ''
                }`}
              />
              <span className="font-bold text-[#505050]">All Events</span>
            </div> */}
            {openSections.allEvents && (
              <div className="ml-6">
                <div className="flex items-center mb-2">
                  {/* All Events content */}
                </div>
              </div>
            )}
          </div>

          {/* Categories Section */}
          <div className="mb-4">
            <div 
              className="flex items-center gap-2 mb-2 cursor-pointer"
              onClick={() => toggleSection('categories')}
            >
              <IoMdArrowDropright 
                className={`text-[#0B5B5C] transition-transform ${
                  openSections.categories ? 'rotate-90' : ''
                }`}
              />
              <span className="font-bold text-[#505050]">Category</span>
            </div>
            {openSections.categories && (
              <div className="ml-6">
                {categories.map((category, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      id={`category-${index}`}
                      className="form-checkbox w-4 h-4 text-[#0B5B5C] focus:ring-[#0B5B5C]"
                      checked={
                        isMobile
                          ? pendingCategories.includes(category)
                          : selectedCategories.includes(category)
                      }
                      onChange={() => handleCategoryChange(category)}
                    />
                    <label
                      htmlFor={`category-${index}`}
                      className="ml-2 text-sm text-gray-600"
                    >
                      {category}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Date Range Section */}
          <div>
            <div 
              className="flex items-center gap-2 mb-2 cursor-pointer"
              onClick={() => toggleSection('dateRange')}
            >
              <IoMdArrowDropright 
                className={`text-[#0B5B5C] transition-transform ${
                  openSections.dateRange ? 'rotate-90' : ''
                }`}
              />
              <span className="font-bold text-[#505050]">Date Range</span>
            </div>
            {openSections.dateRange && (
              <div className="ml-6">
                <div className="mb-2">
                  <label
                    htmlFor="start-date"
                    className="block text-sm text-gray-600 mb-1"
                  >
                    Start on
                  </label>
                  <input
                    type="date"
                    id="start-date"
                    value={isMobile ? pendingStartDate : startDate}
                    onChange={(e) =>
                      isMobile
                        ? setPendingStartDate(e.target.value)
                        : setStartDate(e.target.value)
                    }
                    className="border border-gray-300 rounded-md p-2 w-full focus:ring-[#0B5B5C] focus:border-[#0B5B5C]"
                  />
                </div>
                <div>
                  <label
                    htmlFor="end-date"
                    className="block text-sm text-gray-600 mb-1"
                  >
                    End on
                  </label>
                  <input
                    type="date"
                    id="end-date"
                    value={isMobile ? pendingEndDate : endDate}
                    onChange={(e) =>
                      isMobile
                        ? setPendingEndDate(e.target.value)
                        : setEndDate(e.target.value)
                    }
                    className="border border-gray-300 rounded-md p-2 w-full focus:ring-[#0B5B5C] focus:border-[#0B5B5C]"
                  />
                </div>
                {!isMobile && (
                  <button
                    onClick={handleDateRangeApply}
                    className="mt-4 w-full py-2 bg-[#0B5B5C] text-white rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                  >
                    Apply Date Range
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="md:col-span-3 p-4 ml-4">
        {loading ? (
          <LoadingSkeleton />
        ) : error ? (
          <div className="text-red-500 text-center">Error: {error}</div>
        ) : events.length === 0 ? (
          <NoEventsPlaceholder />
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
              {events.map((event) => (
                <div key={event.id} className="flex-row">
                  <EventsCard event={event} />
                </div>
              ))}
            </div>
          </>
        )}

        {!loading && events.length > 0 && (
          <div className="p-4 flex flex-col items-center">
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={totalItems}
              pageRangeDisplayed={5} // Adjusted for a better range of page numbers
              onChange={handlePageChange}
              innerClass="flex items-center gap-2" // Flex container for pagination items
              itemClass="flex items-center justify-center" // Align items within pagination buttons
              linkClass="w-8 h-8 flex items-center justify-center border border-gray-300 rounded-full text-gray-600 hover:bg-[#0B5B5C] hover:text-white transition duration-200" // Styled pagination links
              activeClass="font-bold" // Adds bold style for the active link container
              activeLinkClass="bg-[#0B5B5C] text-white" // Active link styles
              disabledClass="opacity-50 cursor-not-allowed" // Disabled button styles
              // disabledLinkClass="w-8 h-8 flex items-center justify-center border border-gray-300 rounded-full text-gray-400" // Styled disabled links
              prevPageText={<span>&larr;</span>} // Previous page arrow
              nextPageText={<span>&rarr;</span>} // Next page arrow
            />
          </div>
        )}
      </div>

      {renderMobileApplyButton()}
    </div>
  );
};

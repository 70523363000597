import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import search from '../../assets/images/search.svg';
import HeroImage from '../../assets/images/Hero.svg';
import { Event, eventsService } from '../../Api/services/eventsService';
import { FiSearch, FiCalendar, FiMapPin } from 'react-icons/fi';

export const Hero = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState<Event[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const numberOfImages = 3;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % numberOfImages);
    }, 3000);

    return () => clearInterval(interval);
  }, [numberOfImages]);

  // Fetch suggestions when search term changes
  useEffect(() => {
    const fetchSuggestions = async () => {
      if (searchTerm.trim().length < 2) {
        setSuggestions([]);
        return;
      }

      try {
        const { data } = await eventsService.getAllEvents(
          1, // page
          5, // limit suggestions to 5
          searchTerm
        );
        setSuggestions(data);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
      }
    };

    const debounceTimer = setTimeout(fetchSuggestions, 300);
    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (event: Event) => {
    navigate(`/events/${event.id}`);
    setSearchTerm('');
    setShowSuggestions(false);
  };

  const handleSearch = () => {
    if (searchTerm.trim()) {
      // Instead of navigating, just clear the search
      setSearchTerm('');
      setShowSuggestions(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="bg-[#0A5A5B] grid grid-cols-1 md:grid-cols-2">
      <div className="flex flex-col justify-center px-6 md:px-12 py-12 md:py-0 text-left max-w-7xl mx-auto w-full">
        <div className="text-white text-left flex flex-col gap-6 md:gap-8">
          <h2 className="text-[16px] md:text-[18px] font-bold uppercase tracking-wider">
            Your gateway to amazing local events
          </h2>
          <div className="space-y-1 md:space-y-2">
            <h1 className="text-4xl sm:text-5xl md:text-[64px] font-bold leading-tight">
              Find More Of The
            </h1>
            <h1 className="text-4xl sm:text-5xl md:text-[64px] font-bold leading-tight">
              Events You Love
            </h1>
          </div>
          <p className="text-[16px] md:text-[18px] leading-relaxed">
            From live performances to exclusive gatherings,<br className="hidden md:block" />
            book your tickets now for a front-row experience like never before.<br className="hidden md:block" />
            Your next unforgettable moment starts here.
          </p>
          
          <div className="relative flex flex-col w-full max-w-md mt-6">
            <div className="flex  sm:flex-row items-stretch bg-[#61898A] rounded-none shadow-lg overflow-hidden">
              <div className="flex-grow flex items-center p-2 md:p-2">
                <div className="flex items-center w-full gap-3">
                  <FiSearch className="w-5 h-5 text-gray-400 flex-shrink-0" />
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onKeyPress={handleKeyPress}
                    placeholder="Search events..."
                    className="w-full bg-transparent text-gray-800 placeholder-gray-400 px-2 py-2 outline-none text-base"
                  />
                </div>
              </div>
              <button 
                onClick={handleSearch}
                className="px-8 py-4 bg-[#FCFCFC] text-gray-800 font-semibold hover:bg-white transition-colors duration-200 flex items-center justify-center"
              >
                Search
              </button>
            </div>

            {/* Updated suggestions dropdown */}
            {showSuggestions && suggestions.length > 0 && (
              <div className="absolute top-full left-0 right-0 mt-2 bg-white rounded-lg shadow-xl z-50 max-h-[60vh] overflow-y-auto">
                {suggestions.map((event) => (
                  <div
                    key={event.id}
                    onClick={() => handleSuggestionClick(event)}
                    className="hover:bg-gray-50 cursor-pointer transition-colors duration-150"
                  >
                    <div className="p-4 border-b border-gray-100">
                      <div className="text-gray-800 font-medium mb-2 line-clamp-1">
                        {event.name}
                      </div>
                      <div className="flex items-center gap-4 text-sm text-gray-500">
                        <div className="flex items-center gap-1">
                          <FiCalendar className="w-4 h-4" />
                          <span>{new Date(event.start_time).toLocaleDateString()}</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <FiMapPin className="w-4 h-4" />
                          <span>{event.category}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex">
        <img 
          src={HeroImage} 
          alt="Hero" 
          className="hidden sm:block w-full h-full object-cover" 
        />
      </div>
    </div>
  );
};

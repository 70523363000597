import { supabase } from '../supabase/supabaseClient'
import { toast } from 'react-toastify'

export interface Event {
  id: string
  name: string
  description: string
  date?: string
  location: {
    latitude: number
    longitude: number
  } | string
  created_at: string
  venue: string
  start_time: string
  end_time: string
  category: string
  type: string
  tags: string[]
  event_url: string
  organization_id: string
  address: string
  payout_status: string
  views: number
  publish_status: string
  ticket_sales_status: 'active' | 'paused'
  archived: boolean
  archived_at?: string | null
  poster_url: string
  has_custom_questions: boolean
  allow_refunds: boolean
  organizations?: {
    name: string;
    description: string;
    logo_url: string;
  };
  tickets?: any
  organizer?: {
    name: string;
    description?: string;
    email?: string;
    phone?: string;
    website?: string;
    avatar_url?: string;
  };
}

export class EventsService {
  async getAllEvents(
    page: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    categories: string[] = [],
    startDate?: string,
    endDate?: string
  ): Promise<{ data: Event[], totalPages: number }> {
    try {
      const from = (page - 1) * pageSize;
      const to = from + pageSize - 1;

      let query = supabase
        .from('events')
        .select('*, organizations(name), tickets(price)')
        .eq('publish_status', 'published')
        .range(from, to);

      if (searchTerm) {
        query = query.ilike('name', `%${searchTerm}%`);
      }

      if (categories.length > 0) {
        query = query.in('category', categories);
      }

      if (startDate) {
        query = query.gte('start_time', startDate);
      }

      if (endDate) {
        query = query.lte('start_time', endDate);
      }

      const { data, error } = await query.order('start_time', { ascending: false });

      if (error) {
        toast.error(error.message);
        throw new Error(error.message);
      }

      const totalItems = await this.getTotalItems();
      const totalPages = Math.ceil(totalItems / pageSize);

      return { data: data || [], totalPages };
    } catch (error) {
      toast.error('Failed to fetch events');
      throw error;
    }
  }

  async getTotalItems(): Promise<number> {
    try {
      const { count, error } = await supabase
        .from('events')
        .select('*', { count: 'exact' })
        .eq('publish_status', 'published');

      if (error) {
        toast.error(error.message);
        throw new Error(error.message);
      }

      if (count === null) {
        toast.error('Count is null');
        throw new Error('Count is null');
      }

      return count;
    } catch (error) {
      toast.error('Failed to get total items count');
      throw error;
    }
  }

  async getEventById(id: string): Promise<Event | null> {
    try {
      const { data, error } = await supabase
        .from('events')
        .select('*, organizations(name,description,logo_url)')
        .eq('id', id)
        .eq('publish_status', 'published')
        .single()

      if (error) {
        toast.error(error.message);
        throw new Error(error.message);
      }

      return data;
    } catch (error) {
      toast.error('Failed to fetch event details');
      throw error;
    }
  }

  async fetchEventCategories() {
    try {
      const { data, error } = await supabase
        .from('event_categories')
        .select('*')
    
      if (error) {
        toast.error('Error fetching event categories');
        console.error('Error fetching event categories:', error);
        return;
      }
    
      return data;
    } catch (error) {
      toast.error('Failed to fetch event categories');
      throw error;
    }
  }

  async createEvent(event: Omit<Event, 'id' | 'created_at'>): Promise<Event> {
    try {
      const { data, error } = await supabase
        .from('events')
        .insert([event])
        .select()
        .single()

      if (error) {
        toast.error(error.message);
        throw new Error(error.message);
      }

      toast.success('Event created successfully!');
      return data;
    } catch (error) {
      toast.error('Failed to create event');
      throw error;
    }
  }
}

export const eventsService = new EventsService() 
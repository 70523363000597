import { supabase } from '../supabase/supabaseClient'

export interface CustomQuestion {
  id: string
  created_at: string
  question: string
  is_required: boolean
  options: Record<string, string>
  event_id: string
  organization_id: string
  answer_type: 'text' | 'number' | 'email' | 'single_choice' | 'multiple_choice'
}

export class CustomQuestionsService {
  async getEventCustomQuestions(eventId: string): Promise<CustomQuestion[]> {
    const { data, error } = await supabase
      .from('event_custom_questions')
      .select('*')
      .eq('event_id', eventId)

    if (error) {
      throw new Error(error.message)
    }

    return data || []
  }
}

export const customQuestionsService = new CustomQuestionsService() 
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LandingPage } from '../pages/LandingPage';
import NavBar from '../components/NavBar/NavBar';
import { Events } from '../components/events/Events';
import '../App.css';
import { OrganizersPage } from '../pages/OrganizersPage';
import EventDetail from '../pages/EventDetailPage';
import PaymentStatus from '../pages/PaymentsPage';
import ScrollToTop from '../components/ScrollToTop';
import PaymentsMobile from '../pages/PaymentsMobile';

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <NavBar/>
      <div className="pt-16">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/events" element={<Events />} />
          <Route path="/organizers" element={<OrganizersPage />} />
          <Route path="/events/:id" element={<EventDetail />} />
          <Route path="/payments-succes" element={<PaymentStatus />} />
          <Route path='/payment-success-web' element={<PaymentsMobile/>} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}; 
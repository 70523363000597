import React, { useState } from "react";
import logo from "../../assets/images/Tango Africa logo 1.svg";
import arrow from "../../assets/images/arrow_outward.svg";
import menu from "../../assets/images/menu.svg";
import { Link, NavLink, useLocation } from 'react-router-dom';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isOrganizersPage = location.pathname === '/organizers';

  const handleMenuClick = () => {
    setIsOpen(false);
  };

  const scrollToSection = (sectionId: string) => {
    handleMenuClick();
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNavClick = (section: string) => {
    if (isOrganizersPage) {
      switch(section) {
        case 'features':
          scrollToSection('organizer-features');
          break;
        case 'faq':
          scrollToSection('organizer-faq');
          break;
        case 'contact':
          scrollToSection('footer');
          break;
      }
    } else {
      // Landing page sections
      switch(section) {
        case 'features':
          scrollToSection('landing-features');
          break;
        case 'faq':
          scrollToSection('landing-faq');
          break;
        case 'contact':
          scrollToSection('footer');
          break;
      }
    }
  };

  const handleGetStarted = () => {
    window.open('https://app.tango.co.zw/sign_in', '_blank');
    handleMenuClick(); // Close mobile menu if open
  };

  // Add activeClassName style
  const activeStyle = "border-b-2 border-white";

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-[#0A5A5B] shadow-md p-3">
      <div className="max-w-7xl mx-auto w-full flex flex-row justify-between items-center">
        <div className="flex-shrink-0">
          <Link to='/' onClick={handleMenuClick}>
            <img src={logo} alt="Logo" className="h-[40px] md:h-[54px]" />
          </Link>
        </div>
        <div className="md:hidden">
          <button
            className="text-white bg-[#678F90] rounded-full p-3"
            onClick={() => setIsOpen(!isOpen)}
          >
            <img src={menu} alt="Menu" className="h-4 w-4" />
          </button>
        </div>
        <div className="hidden md:flex md:items-center md:justify-end md:flex-1">
          <ul className="text-white flex flex-row items-center mr-8">           
            <li className="mx-4 cursor-pointer hover:opacity-80 transition-opacity" 
                onClick={() => handleNavClick('features')}>
              Features
            </li>
            <li className="mx-4 cursor-pointer hover:opacity-80 transition-opacity" 
                onClick={() => handleNavClick('faq')}>
              FAQ
            </li>
            <li className="mx-4">
              <NavLink 
                to="/organizers"
                className={({ isActive }) => `hover:opacity-80 transition-opacity ${isActive ? activeStyle : ""}`}
              >
                Organizers
              </NavLink>
            </li>
            <li className="mx-4 cursor-pointer hover:opacity-80 transition-opacity" 
                onClick={() => handleNavClick('contact')}>
              Contact Us
            </li>
          </ul>
          {isOrganizersPage && (
            <button 
              className="bg-[#FCFCFC] text-[#2F2E2E] px-6 py-2 font-bold hover:bg-gray-100 transition-colors flex items-center whitespace-nowrap"
              onClick={handleGetStarted}
            >
              Get Started
              <img src={arrow} alt="Arrow" className="ml-2 h-5" />
            </button>
          )}
        </div>
        <div className={`${
          isOpen ? 'flex' : 'hidden'
        } absolute top-full left-0 right-0 bg-[#0A5A5B] mt-0 shadow-lg md:hidden`}>
          <div className="w-full px-4 py-2">
            <ul className="text-white flex flex-col w-full">           
              <li className="w-full">
                <button 
                  className="w-full text-left p-4 hover:bg-[#678F90] transition-colors" 
                  onClick={() => handleNavClick('features')}
                >
                  Features
                </button>
              </li>
              <li className="w-full">
                <button 
                  className="w-full text-left p-4 hover:bg-[#678F90] transition-colors" 
                  onClick={() => handleNavClick('faq')}
                >
                  FAQ
                </button>
              </li>
              <li className="w-full">
                <NavLink 
                  to="/organizers" 
                  onClick={handleMenuClick}
                  className={({ isActive }) => `block w-full p-4 hover:bg-[#678F90] transition-colors ${isActive ? activeStyle : ""}`}
                >
                  Organizers
                </NavLink>
              </li>
              <li className="w-full">
                <button 
                  className="w-full text-left p-4 hover:bg-[#678F90] transition-colors"
                  onClick={() => handleNavClick('contact')}
                >
                  Contact Us
                </button>
              </li>
            </ul>
            {isOrganizersPage && (
              <button 
                className="bg-[#FCFCFC] text-[#2F2E2E] w-full py-3 px-4 font-bold mt-4 hover:bg-gray-100 transition-colors flex items-center justify-center"
                onClick={handleGetStarted}
              >
                Get Started
                <img src={arrow} alt="Arrow" className="ml-2 h-5" />
              </button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;

import React from 'react';
import Partner1 from '../../assets/images/Patner.jpg'
import Partner2 from '../../assets/images/Patner2.jpg'
import Partner3 from '../../assets/images/Adsco.jpg'
import Partner4 from '../../assets/images/Patner3.jpg'
import Partner5 from '../../assets/images/OtakuCon.jpg'  
import Partner6 from '../../assets/images/Ztn.jpg'
const partners = [
  { id: 1, name: 'NINJA', logo: Partner1 },
  { id: 2, name: 'Rover', logo: Partner2 },
  { id: 3, name: 'Slimydot', logo: Partner3 },
  { id: 4, name: 'Swinger', logo: Partner4 },
  { id: 5, name: 'Loiz Word', logo: Partner5 },
  { id: 6, name: 'ZTN', logo: Partner6 },
];

const PartnerLogos: React.FC = () => {
  return (
    <div className="bg-gray-100 py-8 px-4 md:py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-lg md:text-xl font-semibold text-gray-800 mb-6">
          Join Our Growing List of Partners
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6 items-center justify-items-center">
          {partners.map((partner) => (
            <div key={partner.id} className="w-full max-w-[150px]">
              <img
                src={partner.logo}
                alt={partner.name}
                className="w-full h-auto object-contain grayscale hover:grayscale-0 transition"
                style={{
                  maxHeight: '150px',
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartnerLogos;

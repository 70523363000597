import React from "react";
import { Hero } from "../components/Hero/Hero";
import { Events } from "../components/events/Events";
import Footer from "../components/Footer/Footer";
import FAQComponent from "../components/FaqItem/FAQItem";
import CreateEventCTA from "../components/events/CreateEvent";

export const LandingPage = () => {
  return (
    <div className="font-poppins flex flex-col gap-10">
      <Hero />
      <div id="landing-features">
        <Events />
      </div>
      <CreateEventCTA />
      <div id="landing-faq">
        <FAQComponent />
      </div>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
};

import axios from 'axios';
import { toast } from 'react-toastify';

interface PaymentRequest {
  amount: number;
  currency: string;
  ticketName: string;
  email: string;
}

interface PaymentResponse {
  // Add specific response type based on your API response
  [key: string]: any;
}

export class PaymentService {
  private readonly BASE_URL = 'https://paynow.tango.co.zw';

  async makePayment(
    ticketName: string,
    email: string,
    ticketPrice: number
  ): Promise<PaymentResponse | null> {
    const data: PaymentRequest = {
      amount: ticketPrice,
      currency: 'USD',
      ticketName,
      email,
    };

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${this.BASE_URL}/initiate-payment`,
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    };

    try {
      const response = await axios.request(config);
      console.log('Payment response:', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      console.error('Payment error:', error);
      return null;
    }
  }

  async confirmPayment(pollUrl: string): Promise<PaymentResponse | null> {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${this.BASE_URL}/confirm-payment`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { pollUrl },
    };

    try {
      const response = await axios.request(config);
      console.log('Payment confirmation:', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      console.error('Payment confirmation error:', error);
      return null;
    }
  }
}

export const paymentService = new PaymentService(); 
import React, { useEffect, useState } from 'react';
import { Event, eventsService } from '../../Api/services/eventsService';
import { EventsCard } from './EventsCard';

interface SimilarEventsProps {
  currentEvent: Event;
}

export const SimilarEvents: React.FC<SimilarEventsProps> = ({
  currentEvent,
}) => {
  const [similarEvents, setSimilarEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSimilarEvents = async () => {
      setLoading(true);
      try {
        const { data } = await eventsService.getAllEvents(
          1,
          5,
          '',
          [currentEvent.category]
        );
        const filteredEvents = data
          .filter(event => event.id !== currentEvent.id)
          .slice(0, 5);
        setSimilarEvents(filteredEvents);
      } catch (error) {
        console.error('Error fetching similar events:', error);
      } finally {
        setLoading(false);
      }
    };

    if (currentEvent) {
      fetchSimilarEvents();
    }
  }, [currentEvent]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-32">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#0B5B5C]"></div>
      </div>
    );
  }

  if (similarEvents.length === 0) {
    return null;
  }

  return (
    <div className="w-full py-8">
      <h2 className="text-2xl font-bold text-[#0B5B5C] mb-6">
        Similar Events
      </h2>
      <div className="flex flex-wrap justify-start gap-4">
        {similarEvents.map((event) => (
          <div
            key={event.id}
            className="bg-white shadow-md rounded-lg overflow-hidden w-64"
          >
            <EventsCard event={event} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SimilarEvents;

import React, { useState } from 'react';

type FAQ = {
  question: string;
  answer: string;
};

const faqData: FAQ[] = [
  {
    question: "What is Tango?",
    answer: "Tango is a platform that allows you to manage and attend events seamlessly.",
  },
  {
    question: "How does Tango work?",
    answer: "Tickets can be bought directly through the website or mobile app.",
  },
  {
    question: "How do I get started selling tickets?",
    answer: "Create an organization account, set up events, and start selling tickets.",
  },
  {
    question: "What types of events can I create on Tango?",
    answer: "Tango supports concerts, festivals, conferences, workshops, classes, and more.",
  },
  {
    question: "How much does it cost?",
    answer: "A flat 5% service fee per ticket sold; free events have no fees.",
  },
  {
    question: "Can I pass fees on to attendees?",
    answer: "Yes, fees can be added to ticket prices or split with attendees.",
  },
  {
    question: "How do I scan tickets for my event?",
    answer: "Payments are processed and sent to your account within 48-72 hours.",
  },
  {
    question: "Can I scan tickets offline?",
    answer: "Yes, offline scanning is possible, but multi-device scanning requires an internet connection.",
  },
  {
    question: "Can I issue refunds for my event?",
    answer: "Deleting an upcoming event will automatically refund ticket holders.",
  },
  {
    question: "Can I invite guests to my event through Tango Manager?",
    answer: "Yes, invite guests via the Broadcasts feature after adding them to your contacts.",
  },
  {
    question: "What should I do if I encounter technical issues?",
    answer: "Use the 'Feedback' feature in the app for bug reports or feature requests.",
  },
];

const OrgFAQComponent: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="max-w-7xl mx-auto w-full p-4">
      <h2 className="text-2xl font-bold text-center mb-4">Frequently Asked Questions</h2>
      <p className="text-center mb-8 text-gray-600">
        Find the answers to the most common questions about our product. Feel free to contact us if you don’t find what you are looking for.
      </p>
      <div className="space-y-4">
        {faqData.map((faq, index) => (
          <div key={index} className="border rounded-md shadow-md">
            <button
              onClick={() => toggleFAQ(index)}
              className="w-full flex justify-between items-center p-4 text-left"
            >
              <span className="font-medium">{faq.question}</span>
              <span className="text-gray-500 transform transition-transform duration-300">
                {openIndex === index ? '▲' : '▼'}
              </span>
            </button>
            {openIndex === index && (
              <div className="p-4 text-gray-700 border-t">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrgFAQComponent;

import React, { useEffect, useState } from "react";
import Success from '../assets/images/Successs Icon.svg';
import Failure from '../assets/images/Failure Icon.svg';
import { toast } from 'react-toastify';

const PaymentsMobile: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(true);

  useEffect(() => {
    // Simulate payment processing delay
    const simulateProcessing = setTimeout(() => {
      // Simulated outcome for success or failure
      const isSuccess = Math.random() > 0.5;
      setSuccess(isSuccess);
      setLoading(false);
    }, 3000); // 3 seconds delay

    return () => clearTimeout(simulateProcessing);
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#0B5B5C]"></div>
        <p className="mt-4 text-gray-600">Processing your ticket...</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      {success ? (
        <>
          <img src={Success} alt="Success" className="p-2" />
          <h1 className="mb-2 text-xl font-semibold text-center text-gray-800">
            Your ticket is now being processed.
          </h1>
          <p className="text-center text-gray-600">
            You can now close this window while we process your ticket.
          </p>
        </>
      ) : (
        <>
          <img src={Failure} alt="Failure" className="p-2" />
          <h1 className="mb-2 text-xl font-semibold text-center text-gray-800">
            Ticket processing failed.
          </h1>
          <p className="text-center text-gray-600">
            Please try again later or contact support if the issue persists.
          </p>
        </>
      )}
    </div>
  );
};

export default PaymentsMobile;

import React from "react";
import { useNavigate } from "react-router-dom";
import { Event } from "../../Api/services/eventsService"; 



export const EventsCard: React.FC<{event:Event}> = ({
  event
}) => {
  const navigate = useNavigate();

  const isExpired = () => {
    if (!event.end_time) return false;
    return new Date(event.end_time) < new Date();
  };

  const handleClick = () => {
    navigate(`/events/${event.id}`);
  };

  return (
    <div 
      className={`max-w-sm rounded-none overflow-hidden shadow border border-zinc-200/50 h-96 cursor-pointer hover:shadow-lg transition-shadow relative
        ${isExpired() ? 'opacity-60' : ''}`}
      onClick={handleClick}
    >
      {/* Top Image Section */}
      <div className="relative">
        <img 
          src={event.poster_url} 
          alt="Event Background" 
          className={`h-[296px] w-[296px] object-cover ${isExpired() ? 'grayscale' : ''}`} 
        />
        <div className="absolute top-2 right-2 bg-black text-white text-xs font-semibold px-2 py-1 rounded-none">
          from ${Math.min(...event.tickets.map((ticket: { price: number }) => ticket.price))}
        </div>
        {isExpired() && (<></>
          
        )}
      </div>
      
      {/* Bottom Information Section */}
      <div className="bg-[#0B5B5C] p-1 text-white flex items-center space-x-4">
        <div className="bg-white text-[#0B5B5C] font-bold text-center rounded-none p-2 flex items-center justify-center">
          <div>
            <p className="text-lg">{event.start_time ? new Date(event.start_time).toLocaleString('default', { month: 'short' }) : ''}</p>
            <p className="text-2xl">{event.start_time ? new Date(event.start_time).getDate() : ''}</p>
          </div>
        </div>
        <div className="flex flex-col gap-1 min-w-0">
          <h3 
            className="text-sm font-semibold truncate cursor-help" 
            title={event.name}
          >
            {event.name}
          </h3>
          <p 
            className="text-xs truncate cursor-help" 
            title={event.venue}
          >
            {event.venue}
          </p>
          <p className="text-xs flex items-center p-1">
            <svg 
              className="w-4 h-4 mr-1 flex-shrink-0" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" 
              />
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" 
              />
            </svg>
            <span 
              className="truncate cursor-help" 
              title={event.address}
            >
              {event.address}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { FaChartBar, FaBroadcastTower, FaAddressBook, FaMoneyBillWave, FaTicketAlt, FaMobileAlt } from "react-icons/fa";

const features = [
  {
    icon: <FaChartBar size={24} />,
    title: "Analytics",
    description:
      "Gain deep insights into your event performance with advanced analytics. Track everything from ticket sales and revenue to event trends and attendee demographics. Stay on top of the action as it happens.",
  },
  {
    icon: <FaBroadcastTower size={24} />,
    title: "Broadcasts",
    description:
      "Promote your event to a breeze with Tango broadcasts. Effortlessly send invites to your attendees, followers, or attendees from previous events. Reach your audience quickly and efficiently, making event promotion simpler than ever.",
  },
  {
    icon: <FaAddressBook size={24} />,
    title: "Contacts",
    description:
      "Effortlessly manage and organize your contacts for quick, efficient invitations. Whether you're hosting a small gathering or a larger event, promoting your event has never been easier.",
  },
  {
    icon: <FaMoneyBillWave size={24} />,
    title: "Payout",
    description:
      "Your ticket earnings are automatically deposited into your bank account within 48 hours of your event ending, ensuring quick and secure access to your funds.",
  },
  {
    icon: <FaTicketAlt size={24} />,
    title: "Flexible Ticketing",
    description:
      "Tango supports a variety of ticket types, including standard, promos, member-only options, and more. Make ticketing simple with Tango's flexible solutions to meet your needs.",
  },
  {
    icon: <FaMobileAlt size={24} />,
    title: "Ticket Scanner App",
    description:
      "Streamline check-ins with our mobile-friendly scanner app, designed for quick processing. Faster entry means an improved experience for your attendees.",
  },
];

const FeaturesGrid: React.FC = () => {
  return (
    <div className="max-w-6xl mx-auto my-8 p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {features.map((feature, index) => (
        <div key={index} className="flex flex-col items-start bg-white shadow-md rounded-lg p-6">
          <div className="bg-[#0B5B5C] text-white rounded-full p-2 mb-4">{feature.icon}</div>
          <h3 className="text-xl font-bold text-[#0B5B5C] mb-2">{feature.title}</h3>
          <p className="text-gray-700">{feature.description}</p>
        </div>
      ))}
    </div>
  );
};

export default FeaturesGrid;

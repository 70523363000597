import React, { useEffect, useState } from "react";

const Statistics: React.FC = () => {
  const stats = [
    { end: 28, label: "Events Hosted" },
    { end: 2094, label: "Tickets Sold" },
    { end: 13, label: "Happy Organizers" },
  ];

  const [counts, setCounts] = useState<number[]>([0, 0, 0]);

  useEffect(() => {
    stats.forEach((stat, index) => {
      let start = 0;
      const increment = stat.end / (2 * 60); // Adjust duration here (2 seconds with 60 FPS)
      const timer = setInterval(() => {
        start += increment;
        if (start >= stat.end) {
          clearInterval(timer);
          setCounts((prevCounts) => {
            const newCounts = [...prevCounts];
            newCounts[index] = stat.end;
            return newCounts;
          });
        } else {
          setCounts((prevCounts) => {
            const newCounts = [...prevCounts];
            newCounts[index] = Math.floor(start);
            return newCounts;
          });
        }
      }, 1000 / 60); // 60 FPS

      return () => clearInterval(timer);
    });
  }, []);

  return (
    <div className="max-w-7xl mx-auto my-8">
      <div className="flex flex-col md:flex-row justify-between items-center gap-8  rounded-md p-8">
        {stats.map((stat, index) => (
          <div key={index} className="text-center">
            <p className="text-[80px] font-bold text-[#0B5B5C]">
              {counts[index]}
            </p>
            <p className="text-gray-600">{stat.label}</p>
          </div>
        ))}
      </div>
      <div className="text-center mt-8">
        <h3 className="text-2xl font-bold text-[#505050]">What we offer</h3>
        <p className="text-gray-700 mt-4 max-w-2xl mx-auto">
          Organize and promote events effortlessly with the Tango Manager app. Whether you're a professional event organizer or just planning a small gathering, our user-friendly web app offers everything you need to create, list, and promote your events with ease.
        </p>
      </div>
    </div>
  );
};

export default Statistics;

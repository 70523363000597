import { authService } from './authService';
import { Session } from '@supabase/supabase-js';

const USER_STORAGE_KEY = 'ticketing_user';

interface VerificationResponse {
  success: boolean;
  message: string;
  user?: {
    id: string;
    email: string | null;
    session: Session;
  };
}

export const emailVerificationService = {
  sendVerificationCode: async (email: string): Promise<VerificationResponse> => {
    try {
      await authService.signInWithEmail(email);
      return {
        success: true,
        message: 'Verification code sent successfully'
      };
    } catch (error) {
      console.error('Error sending verification code:', error);
      return {
        success: false,
        message: 'Failed to send verification code'
      };
    }
  },

  verifyCode: async (email: string, code: string): Promise<VerificationResponse> => {
    try {
      const session = await authService.verifyOtp(email, code);
      if (session && session.user) {
        // Create user object with relevant information
        const user = {
          id: session.user.id,
          email: session.user.email || email,
          session: session
        };

        // Store user in localStorage
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));

        return {
          success: true,
          message: 'Email verified successfully',
          user
        };
      }
      return {
        success: false,
        message: 'Invalid verification code'
      };
    } catch (error) {
      console.error('Error verifying code:', error);
      return {
        success: false,
        message: 'Failed to verify code'
      };
    }
  },

  // Utility functions to manage user storage
  getCurrentUser: () => {
    const userStr = localStorage.getItem(USER_STORAGE_KEY);
    return userStr ? JSON.parse(userStr) : null;
  },

  clearCurrentUser: () => {
    localStorage.removeItem(USER_STORAGE_KEY);
  }
}; 
import React from 'react'
import Analytics from '../assets/images/Analytics.svg'
import { OrganizersHero } from '../components/Hero/OrganizersHero'
import Statistics from '../components/DownloadSection/DownloadSection'
import FeaturesGrid from '../components/Cards/Cards'
import Footer from '../components/Footer/Footer'
import OrgFAQComponent from '../components/Faq/OrganizerFaqs'
import TestimonialCarousel from '../components/Testimonials/Testimonials'
import PartnerLogos from '../components/Partners/Partners'

export const OrganizersPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen w-full">
        <OrganizersHero />
        <div className="px-4 md:px-6 lg:px-8">
          <Statistics />
          <div id="organizer-features">
            <FeaturesGrid />
          </div>
          <PartnerLogos />
          <div id="organizer-faq">
            <OrgFAQComponent />
          </div>
          <TestimonialCarousel />
        </div>
        <div id="footer">
          <Footer />
        </div>
    </div>
  )
}

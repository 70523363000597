import { supabase } from '../supabase/supabaseClient'

export interface Ticket {
  id: string
  created_at: string
  price: number
  name: string
  description: string
  quantity: number
  available: number
  event_id: string
  sales_start_date: string
  sales_end_date: string
  starting_price: number
  pricing_type: string
  secret_code: string | null
  allowable_entries: number
  ticket_limit: number
  service_fee: number
  organization_id: string
  fee_handling: string
  ticket_type: string
  sales_status: 'active' | 'paused'
}

export class TicketsService {
  async getEventTickets(eventId: string): Promise<Ticket[]> {
    const { data, error } = await supabase
      .from('tickets')
      .select('*')
      .eq('event_id', eventId)

    if (error) {
      throw new Error(error.message)
    }

    return data || []
  }
}

export const ticketsService = new TicketsService() 
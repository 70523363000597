import React, { useState } from 'react';

type FAQ = {
  question: string;
  answer: string;
};

const faqData: FAQ[] = [
  {
    question: "What is Tango?",
    answer:
      "Tango is a platform that allows you to manage and attend events seamlessly.",
  },
  {
    question: "How do I buy a ticket?",
    answer:
      "You can buy tickets directly through our website or mobile app.",
  },
  {
    question: "Can I get or use a physical ticket to enter into Events?",
    answer:
      "Yes, you can choose to receive a physical ticket or a digital ticket.",
  },
  {
    question: "Can I return my ticket for a refund?",
    answer:
      "Yes, tickets can be returned for a refund under certain conditions.",
  },
  {
    question: "Can I transfer my ticket to another user?",
    answer:
      "Yes, tickets can be transferred to another user before the event starts.",
  },
];

const FAQComponent: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="max-w-7xl mx-auto w-full p-4">
      <h2 className="text-2xl font-bold text-center mb-4">Frequently Asked Questions</h2>
      <p className="text-center mb-8 text-gray-600">
        Find the answers to the most common questions about our product. Feel free to contact us if you don’t find what you are looking for.
      </p>
      <div className="space-y-4">
        {faqData.map((faq, index) => (
          <div key={index} className="border rounded-md shadow-md">
            <button
              onClick={() => toggleFAQ(index)}
              className="w-full flex justify-between items-center p-4 text-left"
            >
              <span className="font-medium">{faq.question}</span>
              <span className="text-gray-500 transform transition-transform duration-300">
                {openIndex === index ? '▲' : '▼'}
              </span>
            </button>
            {openIndex === index && (
              <div className="p-4 text-gray-700 border-t">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQComponent;

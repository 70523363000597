import { supabase } from '../supabase/supabaseClient'
import { FunctionsHttpError } from '@supabase/functions-js'

export interface TicketOrderData {
  user_id: string;
  event_id: string;
  is_valid: boolean;
  use_points: boolean;
  ticket_ids: string[];
  distributor_id: string;
  poll_url: string | undefined;
  order_data: Record<string, number>;
  sale_channel: 'pos' | 'mobile' | 'web';
  payment_summary_url: string | undefined;
}

// TicketOrder extends TicketOrderData and adds database fields
export interface TicketOrder extends TicketOrderData {
  id: string;
  created_at: string;
}

const DISTRIBUTOR_ID = 'dd144c78-85f5-4b54-bb73-2d1b2b72f762';

// Add this function to validate the order data
const validateOrderData = (data: Omit<TicketOrderData, 'distributor_id'>) => {
  if (!data.user_id) throw new Error('user_id is required');
  if (!data.event_id) throw new Error('event_id is required');
  if (!data.ticket_ids || !data.ticket_ids.length) throw new Error('ticket_ids are required');
  if (!data.order_data || Object.keys(data.order_data).length === 0) {
    throw new Error('order_data is required');
  }
  if (!data.sale_channel) throw new Error('sale_channel is required');
  if (!['pos', 'mobile', 'web'].includes(data.sale_channel)) {
    throw new Error('Invalid sale_channel value');
  }
};

export const ticketOrdersService = {
  fetchTicketOrders: async (userId: string, ticketId: string) => {
    const { data, error } = await supabase
      .from('ticket_orders')
      .select("*")
      .eq("user_id", userId)
      .eq("ticket_id", ticketId);

    if (error) {
      console.error('Error fetching ticket orders:', error);
      throw error;
    }

    return data;
  },

  getTicketOrderById: async (orderId: string): Promise<TicketOrder | null> => {
    try {
      const { data, error } = await supabase
        .from('ticket_orders')
        .select('*')
        .eq('id', orderId)
        .single();

      if (error) {
        console.error('Error fetching ticket order:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Error in getTicketOrderById:', error);
      throw new Error('Failed to fetch ticket order');
    }
  },

  invokeNewTicketOrder: async (orderData: Omit<TicketOrderData, 'distributor_id'>) => {
    try {
      // Validate the order data before sending
      validateOrderData(orderData);

      const orderDataWithDistributor = {
        ...orderData,
        distributor_id: DISTRIBUTOR_ID
      };

      console.log('Sending order data:', JSON.stringify(orderDataWithDistributor, null, 2));

      const sanitizedOrderData = JSON.parse(JSON.stringify(orderDataWithDistributor));

      const response = await supabase.functions.invoke('new-ticket-order', {
        body: sanitizedOrderData,
        // headers: {
        //   'Content-Type': 'application/json'
        // }
      });

      if (response.error) {
        if (response.error instanceof FunctionsHttpError) {
          // Try to get the response body
          const errorContext = response.error.context as Response;
          let errorBody;
          
          try {
            errorBody = await errorContext.clone().json();
            console.error('Error response body:', errorBody);
          } catch (e) {
            console.error('Could not parse error response body');
          }

          console.error('Function error details:', {
            message: response.error.message,
            status: errorContext.status,
            statusText: errorContext.statusText,
            errorBody
          });

          // Handle specific status codes
          switch (errorContext.status) {
            case 400:
              throw new Error(errorBody?.message || 'Invalid order data provided');
            case 401:
              throw new Error(errorBody?.message || 'Unauthorized - Please log in again');
            case 403:
              throw new Error(errorBody?.message || 'Permission denied');
            case 404:
              throw new Error(errorBody?.message || 'Order creation endpoint not found');
            case 500:
              throw new Error(errorBody?.message || 'Server error while creating order');
            default:
              throw new Error(errorBody?.message || `Order creation failed: ${response.error.message}`);
          }
        }
        throw response.error;
      }

      if (!response.data) {
        throw new Error('No data returned from the function');
      }

      console.log(response)

      return response.data;
    } catch (error) {
      console.error('Error details:', error);
      
      if (error instanceof Error) {
        // Re-throw user-friendly errors we created above
        if (error.message.includes('Order creation failed') ||
            error.message.includes('Invalid order data') ||
            error.message.includes('Unauthorized') ||
            error.message.includes('Permission denied') ||
            error.message.includes('Server error')) {
          throw error;
        }
        // For other errors, throw a generic message
        throw new Error(`Failed to create ticket order: ${error.message}`);
      }
      
      throw new Error('Failed to create ticket order');
    }
  },

  updateOrderValidity: async (orderId: string): Promise<TicketOrder> => {
    try {
      const { data, error } = await supabase
        .from('ticket_orders')
        .update({ is_valid: true })
        .eq('id', orderId)
        .select()
        .single();

      if (error) {
        console.error('Error updating ticket order validity:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Error in updateOrderValidity:', error);
      throw new Error('Failed to update ticket order validity');
    }
  }
}; 
import React from 'react';

const ShimmerLoader: React.FC = () => {
  const shimmerClass = "bg-shimmer-gradient bg-[length:200%_100%] animate-shimmer";

  return (
    <div className="max-w-6xl mx-auto my-4 px-2 sm:px-4 md:my-8">
      {/* Header Shimmer */}
      <div className={`h-8 rounded w-1/3 mb-8 ${shimmerClass}`}></div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 sm:gap-8">
        {/* Main Content Shimmer */}
        <div className="lg:col-span-2 order-2 lg:order-1">
          {/* Image Placeholder */}
          <div className={`rounded-lg h-[300px] mb-6 ${shimmerClass}`}></div>
          
          {/* Title and Description Shimmers */}
          <div className={`h-6 rounded w-2/3 mb-4 ${shimmerClass}`}></div>
          <div className={`h-4 rounded w-1/3 mb-4 ${shimmerClass}`}></div>
          <div className="space-y-2 mb-6">
            <div className={`h-4 rounded w-full ${shimmerClass}`}></div>
            <div className={`h-4 rounded w-5/6 ${shimmerClass}`}></div>
            <div className={`h-4 rounded w-4/6 ${shimmerClass}`}></div>
          </div>

          {/* Tags Shimmer */}
          <div className={`h-6 rounded w-1/4 mb-4 ${shimmerClass}`}></div>
          <div className="flex gap-2 mb-6">
            {[1, 2, 3].map((i) => (
              <div key={i} className={`h-8 rounded-full w-20 ${shimmerClass}`}></div>
            ))}
          </div>

          {/* Organizer Section Shimmer */}
          <div className={`h-6 rounded w-1/4 mb-4 ${shimmerClass}`}></div>
          <div className="flex items-center gap-2 mb-4">
            <div className={`w-10 h-10 rounded-full ${shimmerClass}`}></div>
            <div className={`h-6 rounded w-1/3 ${shimmerClass}`}></div>
          </div>
        </div>

        {/* Order Summary Card Shimmer */}
        <div className="order-1 lg:order-2">
          <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
            <div className={`h-6 rounded w-1/2 mb-6 ${shimmerClass}`}></div>
            
            {/* Event Details Shimmer */}
            <div className="space-y-4 mb-6">
              {[1, 2, 3, 4].map((i) => (
                <div key={i} className={`h-4 rounded w-2/3 ${shimmerClass}`}></div>
              ))}
            </div>

            <hr className="my-4" />

            {/* Tickets Shimmer */}
            {[1, 2].map((i) => (
              <div key={i} className="mb-4 pb-4 border-b">
                <div className="flex justify-between items-center">
                  <div className="space-y-2">
                    <div className={`h-4 rounded w-32 ${shimmerClass}`}></div>
                    <div className={`h-3 rounded w-24 ${shimmerClass}`}></div>
                  </div>
                  <div className={`h-8 rounded w-24 ${shimmerClass}`}></div>
                </div>
              </div>
            ))}

            {/* Total and Button Shimmer */}
            <div className="flex justify-between items-center mb-4">
              <div className={`h-5 rounded w-16 ${shimmerClass}`}></div>
              <div className={`h-5 rounded w-24 ${shimmerClass}`}></div>
            </div>
            <div className={`h-10 rounded w-full ${shimmerClass}`}></div>
          </div>
        </div>
      </div>

      {/* Similar Events Shimmer */}
      <div className="mt-12">
        <div className={`h-8 rounded w-1/4 mb-6 ${shimmerClass}`}></div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {[1, 2, 3].map((i) => (
            <div key={i} className="bg-white rounded-lg shadow-md overflow-hidden">
              {/* Event Image Shimmer */}
              <div className={`h-48 w-full ${shimmerClass}`}></div>
              
              {/* Event Content Shimmer */}
              <div className="p-4">
                <div className={`h-6 rounded w-3/4 mb-2 ${shimmerClass}`}></div>
                <div className={`h-4 rounded w-1/2 mb-3 ${shimmerClass}`}></div>
                
                {/* Date and Time */}
                <div className="flex items-center gap-2 mb-2">
                  <div className={`h-4 rounded w-24 ${shimmerClass}`}></div>
                  <div className={`h-4 rounded w-16 ${shimmerClass}`}></div>
                </div>
                
                {/* Location */}
                <div className={`h-4 rounded w-2/3 mb-3 ${shimmerClass}`}></div>
                
                {/* Price */}
                <div className="flex justify-between items-center">
                  <div className={`h-5 rounded w-20 ${shimmerClass}`}></div>
                  <div className={`h-8 rounded w-24 ${shimmerClass}`}></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShimmerLoader; 
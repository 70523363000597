import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Ticket, ticketsService } from "../Api/services/ticketsService";
import { Event, eventsService } from "../Api/services/eventsService";
import {
  CustomQuestion,
  customQuestionsService,
} from "../Api/services/customQuestionsService";
import { emailVerificationService } from "../Api/services/emailVerificationService";
import { toast } from "react-toastify";
import { userService } from "../Api/services/userService";
import { paymentRecordsService } from "../Api/services/paymentRecordsService";
import { paymentService } from "../Api/services/paynowService";
import { answerStorageService } from "../Api/services/answerStorageService";
import SimilarEvents from "../components/events/SimilarEvents";
import ShimmerLoader from "../components/loaders/ShimmerLoader";
import { ticketOrdersService } from "../Api/services/ticketOrdersService";
import type { TicketOrderData } from "../Api/services/ticketOrdersService";

interface OrderDetails {
  tickets: {
    ticketId: string;
    quantity: number;
    price: number;
    name: string;
  }[];
  customAnswers: Record<string, string>;
  mandatoryAnswers: Record<string, string>;
  totalAmount: number;
  serviceFees: number;
  email?: string;
}

interface MandatoryQuestion {
  id: string;
  label: string;
}

interface StepInfo {
  number: number;
  label: string;
  canAccess: boolean;
}

const mandatoryQuestions: MandatoryQuestion[] = [
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "phoneNumber", label: "Phone Number" },
  { id: "idDate", label: "DOB" },
  { id: "gender", label: "Gender" },
];

const USER_STORAGE_KEY = "current_user";

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

const EventDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [event, setEvent] = useState<Event | null>(null);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [loading, setLoading] = useState(true);
  const [ticketCounts, setTicketCounts] = useState<Record<string, number>>({});
  const [currentStep, setCurrentStep] = useState(1);
  const [customQuestions, setCustomQuestions] = useState<CustomQuestion[]>([]);
  const [questionAnswers, setQuestionAnswers] = useState<
    Record<string, string>
  >({});
  const [orderDetails, setOrderDetails] = useState<OrderDetails>({
    tickets: [],
    customAnswers: {},
    mandatoryAnswers: {},
    totalAmount: 0,
    serviceFees: 0,
    email: "",
  });
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [currentUser, setCurrentUser] = useState(
    emailVerificationService.getCurrentUser()
  );
  const [selectedTicketType, setSelectedTicketType] = useState<string | null>(
    null
  );
  const [isLoadingSendCode, setIsLoadingSendCode] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [ticketSalesStatus, setTicketSalesStatus] = useState<string>('');
  const [publishStatus, setPublishStatus] = useState<string>('');

  // Load saved answers from localStorage on component mount
  useEffect(() => {
    const savedAnswers = answerStorageService.loadAnswers();

    setOrderDetails((prev) => ({
      ...prev,
      mandatoryAnswers: savedAnswers.mandatory,
      customAnswers: savedAnswers.custom,
    }));

    setQuestionAnswers(savedAnswers.custom);
  }, []);

  useEffect(() => {
    const fetchEventAndTickets = async () => {
      if (!id) return;

      try {
        setLoading(true);
        const [eventData, ticketsData, questionsData] = await Promise.all([
          eventsService.getEventById(id),
          ticketsService.getEventTickets(id),
          customQuestionsService.getEventCustomQuestions(id),
        ]);
        setEvent(eventData);
        setTickets(ticketsData);
        setCustomQuestions(questionsData);
        setTicketSalesStatus(eventData?.ticket_sales_status || '');
        setPublishStatus(eventData?.publish_status || '');

        // If event is not published, redirect to events page
        if (eventData?.publish_status !== 'published') {
          toast.error('This event is not currently available');
          navigate('/events');
          return;
        }

        // Initialize ticket counts and question answers
        const initialCounts = ticketsData.reduce(
          (acc, ticket) => ({ ...acc, [ticket.id]: 0 }),
          {}
        );
        setTicketCounts(initialCounts);

        const initialAnswers = questionsData.reduce(
          (acc, question) => ({ ...acc, [question.id]: "" }),
          {}
        );
        setQuestionAnswers(initialAnswers);
      } catch (error) {
        console.error("Error fetching event details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEventAndTickets();
  }, [id, navigate]);

  const isTicketAvailable = (ticket: Ticket): boolean => {
    const now = new Date();
    const salesStart = new Date(ticket.sales_start_date);
    const salesEnd = new Date(ticket.sales_end_date);

    // Check publish status first
    if (publishStatus !== 'published') {
      return false;
    }

    // Check event-level ticket sales status
    if (ticketSalesStatus === 'paused') {
      return false;
    }

    // Then check individual ticket status
    if (ticket.sales_status === 'paused') {
      return false;
    }

    return now >= salesStart && now <= salesEnd && ticket.available > 0;
  };

  const calculateTicketPrice = (ticket: Ticket): number => {
    return ticket.price;
  };

  const calculateServiceFee = (ticket: Ticket, quantity: number): number => {
    return ticket.fee_handling === "transfer_fee"
      ? ticket.price * ticket.service_fee * quantity
      : 0;
  };

  const calculateSubtotal = (): number => {
    return tickets.reduce((total, ticket) => {
      const count = ticketCounts[ticket.id] || 0;
      return total + calculateTicketPrice(ticket) * count;
    }, 0);
  };

  const calculateTotalServiceFees = (): number => {
    return tickets.reduce((total, ticket) => {
      const count = ticketCounts[ticket.id] || 0;
      return total + calculateServiceFee(ticket, count);
    }, 0);
  };

  const calculateTotal = (): number => {
    return calculateSubtotal() + calculateTotalServiceFees();
  };

  const getTicketAvailabilityMessage = (ticket: Ticket): string => {
    const now = new Date();
    const salesStart = new Date(ticket.sales_start_date);
    const salesEnd = new Date(ticket.sales_end_date);

    // Check publish status first
    if (publishStatus !== 'published') {
      return "Event is not published";
    }

    // Check event-level ticket sales status
    if (ticketSalesStatus === 'paused') {
      return "Event ticket sales are paused";
    }

    // Then check individual ticket status
    if (ticket.sales_status === 'paused') {
      return "Sales temporarily paused";
    }

    if (now < salesStart) {
      return `Sales start on ${salesStart.toLocaleDateString()}`;
    }
    if (now > salesEnd) {
      return "Sales ended";
    }
    if (ticket.available <= 0) {
      return "Sold out";
    }
    return `${ticket.available} tickets left`;
  };

  const handleTicketChange = (ticketId: string, increment: boolean) => {
    setTicketCounts((prevCounts) => {
      const ticket = tickets.find((t) => t.id === ticketId);
      
      // Check publish status first
      if (publishStatus !== 'published') {
        toast.error('This event is not currently available for ticket purchases');
        return prevCounts;
      }

      // Check event-level ticket sales status
      if (ticketSalesStatus === 'paused') {
        toast.error('Event ticket sales are currently paused');
        return prevCounts;
      }

      if (!ticket || !isTicketAvailable(ticket)) {
        // Show error message if trying to select a paused ticket
        if (ticket?.sales_status === 'paused') {
          toast.error('Ticket sales are temporarily paused');
        }
        return prevCounts;
      }

      const currentCount = prevCounts[ticketId] || 0;
      const newCount = increment
        ? currentCount + 1
        : Math.max(currentCount - 1, 0);

      // Check limits
      if (increment && newCount > ticket.ticket_limit) {
        toast.error(
          `You can only purchase up to ${ticket.ticket_limit} tickets of this type`
        );
        return prevCounts;
      }

      if (increment && newCount > ticket.available) {
        toast.error("Not enough tickets available");
        return prevCounts;
      }

      // Check if trying to select different ticket type
      if (increment && selectedTicketType && selectedTicketType !== ticketId) {
        toast.error("You can only select one ticket type at a time");
        return prevCounts;
      }

      // Update selected ticket type
      if (newCount === 0) {
        setSelectedTicketType(null);
      } else if (increment && currentCount === 0) {
        setSelectedTicketType(ticketId);
      }

      const newCounts = { ...prevCounts, [ticketId]: newCount };

      // Update orderDetails with new ticket information and separated fees
      setOrderDetails((prev) => ({
        ...prev,
        tickets: tickets
          .filter((t) => newCounts[t.id] > 0)
          .map((t) => ({
            ticketId: t.id,
            quantity: newCounts[t.id],
            price: calculateTicketPrice(t),
            name: t.name,
          })),
        totalAmount: calculateSubtotal(),
        serviceFees: calculateTotalServiceFees(),
      }));

      return newCounts;
    });
  };

  const handleNextStep = () => {
    if (canProceedToNextStep()) {
      setCurrentStep((prev) => prev + 1);
    }
  };
  const handlePreviousStep = () =>
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));

  const handleQuestionAnswer = (questionId: string, answer: string) => {
    answerStorageService.saveCustomAnswer(questionId, answer);
    setQuestionAnswers((prev) => ({
      ...prev,
      [questionId]: answer,
    }));
    setOrderDetails((prev) => ({
      ...prev,
      customAnswers: {
        ...prev.customAnswers,
        [questionId]: answer,
      },
    }));
  };

  const handleEmailChange = (email: string) => {
    setOrderDetails((prev) => ({
      ...prev,
      email,
    }));
  };

  const handlePhoneNumberChange = (phoneNumber: string) => {
    setOrderDetails((prev) => ({
      ...prev,
      phoneNumber,
    }));
  };

  const handleMandatoryAnswer = (questionId: string, answer: string) => {
    // Special handling for phone number
    if (questionId === "phoneNumber") {
      // Remove any non-digit characters
      const numbersOnly = answer.replace(/[^\d]/g, "");

      // Only update if the input is empty or contains only numbers
      if (answer === "" || answer === numbersOnly) {
        answerStorageService.saveMandatoryAnswer(questionId, numbersOnly);
        setOrderDetails((prev) => ({
          ...prev,
          mandatoryAnswers: {
            ...prev.mandatoryAnswers,
            [questionId]: numbersOnly,
          },
        }));
      }
      return;
    }

    // Handle other mandatory fields normally
    answerStorageService.saveMandatoryAnswer(questionId, answer);
    setOrderDetails((prev) => ({
      ...prev,
      mandatoryAnswers: {
        ...prev.mandatoryAnswers,
        [questionId]: answer,
      },
    }));
  };

  const handleSendVerificationCode = async () => {
    if (!orderDetails.email) {
      toast.error("Please enter your email address");
      return;
    }

    try {
      setIsLoadingSendCode(true);
      setEmailError("");
      const result = await emailVerificationService.sendVerificationCode(
        orderDetails.email
      );
      if (result.success) {
        setIsEmailSent(true);
        toast.success("Verification code sent successfully!");
      } else {
        toast.error(result.message || "Failed to send verification code");
        setEmailError(result.message);
      }
    } catch (error) {
      toast.error("Failed to send verification code. Please try again.");
      setEmailError("Failed to send verification code. Please try again.");
    } finally {
      setIsLoadingSendCode(false);
    }
  };

  const handleVerifyCode = async () => {
    if (!verificationCode) {
      toast.error("Please enter the verification code");
      return;
    }

    try {
      setIsLoadingVerify(true);
      setEmailError("");
      const result = await emailVerificationService.verifyCode(
        orderDetails.email!,
        verificationCode
      );
      if (result.success) {
        setIsEmailVerified(true);
        toast.success("Email verified successfully!");
        if (result.user) {
          localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(result.user));
          setCurrentUser(result.user);
          if (result.user.email) {
            handleMandatoryAnswer("email", result.user.email);
          }
        }
      } else {
        toast.error(result.message || "Invalid verification code");
        setEmailError(result.message);
      }
    } catch (error) {
      toast.error("Failed to verify code. Please try again.");
      setEmailError("Failed to verify code. Please try again.");
    } finally {
      setIsLoadingVerify(false);
    }
  };

  const canProceedToNextStep = (): boolean => {
    switch (currentStep) {
      case 1:
        if (!orderDetails.tickets.length) {
          toast.error("Please select at least one ticket to proceed");
          return false;
        }
        return true;

      case 2:
        // Check mandatory questions first
        const unansweredMandatory = mandatoryQuestions.find((question) => {
          const answer = orderDetails.mandatoryAnswers[question.id];
          return !answer || answer.trim() === "";
        });

        if (unansweredMandatory) {
          toast.error(
            `Please answer all mandatory questions before proceeding`
          );
          return false;
        }

        // Check custom questions
        const unansweredRequired = customQuestions.find((question) => {
          if (question.is_required) {
            const answer = orderDetails.customAnswers[question.id];
            return !answer || answer.trim() === "";
          }
          return false;
        });

        if (unansweredRequired) {
          toast.error(`Please answer all event questions before proceeding`);
          return false;
        }

        // If we get here, all required questions are answered
        toast.success("All questions answered successfully!");
        return true;

      case 3:
        if (!isEmailVerified) {
          toast.error("Please verify your email address to proceed");
          return false;
        }
        return true;

      case 4:
        if (!orderDetails.mandatoryAnswers.phoneNumber) {
          toast.error(
            "Please enter your phone number in the registration step"
          );
          return false;
        }
        return true;

      default:
        return true;
    }
  };

  const handleCompletePurchase = async () => {
    if (!orderDetails.mandatoryAnswers.phoneNumber) {
      toast.error("Please enter your phone number in the registration step");
      return;
    }

    try {
      const storedUser = localStorage.getItem(USER_STORAGE_KEY);
      if (!storedUser) {
        toast.error("User session expired. Please verify your email again.");
        return;
      }

      const user = JSON.parse(storedUser);

      // Prepare user data from mandatory answers
      const userData = {
        email: orderDetails.email!,
        first_name: orderDetails.mandatoryAnswers.firstName,
        last_name: orderDetails.mandatoryAnswers.lastName,
        phone: orderDetails.mandatoryAnswers.phoneNumber,
        dob: orderDetails.mandatoryAnswers.idDate,
        gender: orderDetails.mandatoryAnswers.gender,
      };

      // Update user with stored user ID
      const updatedUser = await userService.upsertUser(user.id, userData);

      if (!updatedUser) {
        toast.error("Failed to save user information");
        return;
      }

      // Process each ticket in the order
      for (const ticketOrder of orderDetails.tickets) {
        const ticket = tickets.find((t) => t.id === ticketOrder.ticketId);

        if (!ticket) {
          toast.error("Invalid ticket selection");
          return;
        }

        const isFreeTicket = ticket.price === 0;
        const totalAmount =
          ticket.price * ticketOrder.quantity +
          calculateServiceFee(ticket, ticketOrder.quantity);

        if (isFreeTicket) {
          // Handle free tickets
          const orderData: Omit<TicketOrderData, "distributor_id"> = {
            user_id: updatedUser.id,
            event_id: event?.id || "",
            is_valid: true,
            use_points: false,
            ticket_ids: [ticket.id],
            poll_url: "none",
            order_data: { [ticket.id]: ticketOrder.quantity },
            sale_channel: "web" as const,
            payment_summary_url: "",
          };

          const ticketOrderResponse =
            await ticketOrdersService.invokeNewTicketOrder(orderData);
          console.log("here is what you require", ticketOrderResponse);

          if (ticketOrderResponse) {
            const paymentData = {
              user_id: updatedUser.id,
              event_id: event?.id,
              ticket_id: ticket.id,
              poll_url: "none",
              use_points: false,
              ticket_price: 0,
              admission_count: ticketOrder.quantity,
              payment_success: true,
              service_fee_amount: 0,
              payment_summary_url: "",
            };

            const paymentRecord =
              await paymentRecordsService.createEventTicketPayment(paymentData);
            if (paymentRecord) {
              localStorage.setItem(
                "Ticket_Order",
                JSON.stringify(ticketOrderResponse)
              );
              localStorage.setItem(
                "paymentRecord",
                JSON.stringify(paymentRecord)
              );
              navigate(`/payments`);
            }
          }
        } else {
          // For paid tickets, first initiate payment with Paynow
          const paynowResponse = await paymentService.makePayment(
            ticket.name,
            orderDetails.email!,
            totalAmount
          );

          if (!paynowResponse) {
            toast.error("Payment initiation failed");
            return;
          }

          const urlObject = new URL(paynowResponse.pollUrl);
          const guidParam = urlObject.searchParams.get('guid');    

          // Create order data with payment URLs from Paynow
          const orderData: Omit<TicketOrderData, "distributor_id"> = {
            user_id: updatedUser.id,
            event_id: event?.id || "",
            is_valid: false,
            use_points: false,
            ticket_ids: [ticket.id],
            poll_url: paynowResponse.pollUrl,
            sale_channel: "web" as const,
            order_data: { [ticket.id]: ticketOrder.quantity },
            payment_summary_url: `https://www.paynow.co.zw/Transaction/TransactionView/?guid=${guidParam}`,
          };

          // Create ticket order
          const ticketOrderResponse =
            await ticketOrdersService.invokeNewTicketOrder(orderData);

          if (ticketOrderResponse) {
            // Create payment record
            const paymentData = {
              user_id: updatedUser.id,
              event_id: event?.id,
              ticket_id: ticket.id,
              poll_url: paynowResponse.pollUrl,
              use_points: false,
              ticket_price: ticket.price * ticketOrder.quantity,
              admission_count: ticketOrder.quantity,
              payment_success: false,
              service_fee_amount: calculateServiceFee(
                ticket,
                ticketOrder.quantity
              ),
              payment_summary_url: paynowResponse.redirectUrl,
            };

            const paymentRecord =
              await paymentRecordsService.createEventTicketPayment(paymentData);

            if (paymentRecord) {
              localStorage.setItem(
                "Ticket_Order",
                JSON.stringify(ticketOrderResponse)
              );
              localStorage.setItem(
                "paymentRecord",
                JSON.stringify(paymentRecord)
              );
              // Redirect to Paynow payment gateway
              window.location.href = paynowResponse.redirectUrl;
            } else {
              toast.error("Failed to create payment record");
            }
          } else {
            toast.error("Failed to create ticket order");
          }
        }
      }
    } catch (error) {
      console.error("Error completing purchase:", error);
      toast.error("Failed to complete purchase. Please try again.");
    }
  };

  // Add cleanup on component unmount
  useEffect(() => {
    return () => {
      // Optionally clear user data when leaving the page
      // localStorage.removeItem(USER_STORAGE_KEY);
    };
  }, []);

  const getStepInfo = (stepNumber: number): StepInfo => {
    const hasTickets = orderDetails.tickets.length > 0;
    const hasAnsweredQuestions =
      customQuestions.every(
        (q) =>
          !q.is_required ||
          (orderDetails.customAnswers[q.id] &&
            orderDetails.customAnswers[q.id].trim() !== "")
      ) &&
      mandatoryQuestions.every(
        (q) =>
          orderDetails.mandatoryAnswers[q.id] &&
          orderDetails.mandatoryAnswers[q.id].trim() !== ""
      );

    switch (stepNumber) {
      case 1:
        return {
          number: 1,
          label: "Select Ticket",
          canAccess: true,
        };
      case 2:
        return {
          number: 2,
          label: "Registration",
          canAccess: hasTickets,
        };
      case 3:
        return {
          number: 3,
          label: "Verify Email",
          canAccess: hasTickets && hasAnsweredQuestions,
        };
      case 4:
        return {
          number: 4,
          label: "Complete Purchase",
          canAccess: hasTickets && hasAnsweredQuestions && isEmailVerified,
        };
      default:
        return {
          number: 1,
          label: "Select Ticket",
          canAccess: true,
        };
    }
  };

  const handleStepClick = (stepNumber: number) => {
    const stepInfo = getStepInfo(stepNumber);
    if (stepInfo.canAccess) {
      setCurrentStep(stepNumber);
    } else {
      const requirements = {
        2: "Please select at least one ticket first.",
        3: "Please complete the registration form first.",
        4: "Please verify your email first.",
      };
      toast.error(requirements[stepNumber as keyof typeof requirements]);
    }
  };

  if (loading) {
    return <ShimmerLoader />;
  }

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 md:px-8 my-4 md:my-8">
      {/* Step Progress */}
      <div className="w-full mb-6 md:mb-8">
        {/* For small screens */}
        <div className="flex items-center justify-between md:hidden max-w-[280px] mx-auto">
          {[1, 2, 3, 4].map((step, index) => {
            const stepInfo = getStepInfo(step);
            return (
              <div key={step} className="flex items-center w-full">
                <div
                  onClick={() => handleStepClick(step)}
                  className={`flex items-center justify-center rounded-full w-7 h-7 sm:w-8 sm:h-8 
                    ${currentStep >= step ? "bg-[#0B5B5C]" : "bg-gray-300"}
                    ${
                      stepInfo.canAccess
                        ? "cursor-pointer hover:opacity-80"
                        : "cursor-not-allowed"
                    }`}
                >
                  <span className="text-center text-xs text-white font-bold">
                    {step}
                  </span>
                </div>
                {index < 3 && (
                  <div className="flex-1 h-0.5 mx-1 border-t-2 border-dotted border-gray-300"></div>
                )}
              </div>
            );
          })}
        </div>

        {/* For md and above screens */}
        <div className="hidden md:flex flex-col sm:flex-row justify-between items-center gap-1 mb-4 sm:mb-8">
          {[1, 2, 3, 4].map((step) => {
            const stepInfo = getStepInfo(step);
            return (
              <div
                key={step}
                onClick={() => handleStepClick(step)}
                className={`flex-1 w-full p-2 sm:p-3 mb-1 sm:mb-0 
                  ${currentStep >= step ? "bg-[#0B5B5C]" : "bg-gray-300"}
                  ${
                    stepInfo.canAccess
                      ? "cursor-pointer hover:opacity-80"
                      : "cursor-not-allowed"
                  }`}
              >
                <span className="text-center text-xs sm:text-sm text-white font-bold block">
                  {stepInfo.label}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      {/* Step Content */}
      {currentStep === 1 && (
        <div className="space-y-4 md:space-y-6">
          <h2 className="text-xl sm:text-2xl font-bold text-[#0B5B5C]">
            Event Detail
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="lg:col-span-2 order-2 lg:order-1">
              <div className="bg-white rounded-lg shadow-md p-2 sm:p-4 mb-4">
                <img
                  src={
                    event?.poster_url || "https://via.placeholder.com/500x300"
                  }
                  alt={event?.name}
                  className="w-full h-auto rounded-lg object-cover"
                />
              </div>
              <h3 className="text-base sm:text-xl font-semibold">
                {event?.name}
              </h3>
              <p className="text-sm sm:text-base text-gray-600 mb-2">
                {event?.category}
              </p>
              <p className="text-sm sm:text-base text-gray-700 mb-4">
                {event?.description}
              </p>
              <div className="flex flex-wrap gap-2 mb-4">
                {event?.tags?.map((tag, index) => (
                  <span
                    key={index}
                    className="text-xs sm:text-sm inline-block bg-gray-200 rounded-full px-2 py-1"
                  >
                    {tag}
                  </span>
                ))}
              </div>
              <div className="mt-8">
                <h3 className="text-lg font-semibold text-[#0B5B5C] mb-4">
                  Organizer
                </h3>
                <div className="bg-white p-4">
                  <div className="flex items-center gap-4 mb-4">
                    <img
                      src={
                        event?.organizations?.logo_url ||
                        "https://via.placeholder.com/60"
                      }
                      alt={event?.organizations?.name}
                      className="w-12 h-12 rounded-full object-cover"
                    />
                    <div>
                      <h4 className="font-semibold">
                        {event?.organizations?.name}
                      </h4>
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-sm text-gray-600 whitespace-pre-line">
                      {event?.organizations?.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Order summary card - Show at top on mobile */}
            <div className="order-1 lg:order-2 sticky top-0 lg:top-4 bg-white z-10">
              <div className="bg-white rounded-lg shadow-md p-6 max-w-md mx-auto">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">
                  Event Details
                </h2>

                <div className="text-md text-gray-700 space-y-2 mb-4">
                  <p className="flex justify-between">
                    <strong>Date:</strong>{" "}
                    {event?.start_time ? formatDate(event.start_time) : "N/A"}
                  </p>
                  <p className="flex justify-between">
                    <strong>Time:</strong>{" "}
                    {event?.start_time
                      ? new Date(event.start_time).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : "N/A"}
                  </p>

                  <p className="flex justify-between">
                    <strong>Venue:</strong> {event?.venue}
                  </p>
                  <p className="flex justify-between">
                    <strong>Location:</strong> {event?.address}
                  </p>
                </div>
                <hr className="border-gray-300 my-4" />
                <h3 className="text-md font-semibold text-[#0B5B5C] mb-4">
                  Select Ticket(s)
                </h3>
                {tickets.map((ticket) => (
                  <div
                    key={ticket.id}
                    className="flex items-center justify-between mb-4"
                  >
                    <div>
                      <p className="text-sm font-semibold">
                        ${calculateTicketPrice(ticket).toFixed(2)} (
                        {ticket.name})
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        className="w-8 h-8 flex items-center justify-center bg-gray-200 text-gray-600 rounded"
                        onClick={() => handleTicketChange(ticket.id, false)}
                      >
                        -
                      </button>
                      <span className="w-8 h-8 flex items-center justify-center border text-sm font-medium">
                        {ticketCounts[ticket.id] || 0}
                      </span>
                      <button
                        className="w-8 h-8 flex items-center justify-center bg-gray-200 text-gray-600 rounded"
                        onClick={() => handleTicketChange(ticket.id, true)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                ))}
                <hr className="border-gray-300 my-4" />
                <div className="flex justify-between items-center text-lg font-semibold mb-6">
                  <p>Total</p>
                  <p>${calculateSubtotal().toFixed(2)}</p>
                </div>
                <button
                  onClick={handleNextStep}
                  disabled={!orderDetails.tickets.length}
                  className={`w-full py-3 text-lg font-medium rounded-lg
      ${
        !orderDetails.tickets.length
          ? "bg-gray-300 text-gray-500 cursor-not-allowed"
          : "bg-[#0B5B5C] text-white hover:bg-green-700"
      }`}
                >
                  {!orderDetails.tickets.length
                    ? "Select a ticket to proceed"
                    : "Proceed"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div className="space-y-4 md:space-y-6">
          <h2 className="text-xl sm:text-2xl font-bold text-[#0B5B5C]">
            Registration Details
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="lg:col-span-2 order-2 lg:order-1">
              <div className="bg-white rounded-lg shadow-md p-2 sm:p-4 mb-4">
                <img
                  src={
                    event?.poster_url || "https://via.placeholder.com/500x300"
                  }
                  alt={event?.name}
                  className="w-full h-auto rounded-lg object-cover"
                />
              </div>
              <h3 className="text-base sm:text-xl font-semibold">
                {event?.name}
              </h3>
              <p className="text-sm sm:text-base text-gray-600 mb-2">
                {event?.category}
              </p>
              <p className="text-sm sm:text-base text-gray-700 mb-4">
                {event?.description}
              </p>
              <div className="flex flex-wrap gap-2 mb-4">
                {event?.tags?.map((tag, index) => (
                  <span
                    key={index}
                    className="text-xs sm:text-sm inline-block bg-gray-200 rounded-full px-2 py-1"
                  >
                    {tag}
                  </span>
                ))}
              </div>
              <div className="mt-8">
                <h3 className="text-lg font-semibold text-[#0B5B5C] mb-4">
                  Organizer
                </h3>
                <div className="bg-white p-4">
                  <div className="flex items-center gap-4 mb-4">
                    <img
                      src={
                        event?.organizations?.logo_url ||
                        "https://via.placeholder.com/60"
                      }
                      alt={event?.organizations?.name}
                      className="w-12 h-12 rounded-full object-cover"
                    />
                    <div>
                      <h4 className="font-semibold">
                        {event?.organizations?.name}
                      </h4>
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-sm text-gray-600 whitespace-pre-line">
                      {event?.organizations?.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Registration Form Card */}
            <div className="order-1 lg:order-2 sticky top-0 lg:top-4">
              <div className="bg-white rounded-lg shadow-md p-6 max-w-md mx-auto">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">
                  Registration Form
                </h2>

                {/* Personal Information Section */}
                <div className="mb-6">
                  <h3 className="text-base font-semibold text-[#0B5B5C] mb-4">
                    Personal Information
                  </h3>
                  {mandatoryQuestions.map((question) => (
                    <div key={question.id} className="mb-4">
                      <label className="block text-sm sm:text-base font-medium text-gray-700 mb-1">
                        {question.label}
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      {question.id === "gender" ? (
                        <select
                          value={
                            orderDetails.mandatoryAnswers[question.id] || ""
                          }
                          onChange={(e) =>
                            handleMandatoryAnswer(question.id, e.target.value)
                          }
                          className="w-full p-2 sm:p-3 border rounded-md text-sm sm:text-base"
                          required
                        >
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </select>
                      ) : (
                        <input
                          type={
                            question.id === "idDate"
                              ? "date"
                              : question.id === "phoneNumber"
                              ? "tel"
                              : "text"
                          }
                          value={
                            orderDetails.mandatoryAnswers[question.id] || ""
                          }
                          onChange={(e) =>
                            handleMandatoryAnswer(question.id, e.target.value)
                          }
                          className="w-full p-2 sm:p-3 border rounded-md text-sm sm:text-base"
                          required
                          placeholder={`Enter your ${question.label}`}
                          {...(question.id === "phoneNumber" && {
                            pattern: "[0-9]*",
                            inputMode: "numeric",
                            maxLength: 15, // Adjust this number based on your requirements
                          })}
                        />
                      )}
                    </div>
                  ))}
                </div>

                {/* Event Questions Section */}
                {customQuestions.length > 0 && (
                  <div className="mb-6">
                    <h3 className="text-base font-semibold text-[#0B5B5C] mb-4">
                      Event Questions
                    </h3>
                    {customQuestions.map((question) => (
                      <div key={question.id} className="mb-4">
                        <label className="block text-sm sm:text-base font-medium text-gray-700 mb-1">
                          {question.question}
                          {question.is_required && (
                            <span className="text-red-500 ml-1">*</span>
                          )}
                        </label>

                        {question.answer_type === "single_choice" ? (
                          <div className="space-y-2">
                            {Object.entries(question.options).map(
                              ([key, value]) => (
                                <label
                                  key={key}
                                  className="flex items-center space-x-2"
                                >
                                  <input
                                    type="radio"
                                    name={question.id}
                                    value={value}
                                    checked={
                                      questionAnswers[question.id] === value
                                    }
                                    onChange={(e) =>
                                      handleQuestionAnswer(
                                        question.id,
                                        e.target.value
                                      )
                                    }
                                    className="text-[#0B5B5C]"
                                  />
                                  <span className="text-sm sm:text-base">
                                    {value}
                                  </span>
                                </label>
                              )
                            )}
                          </div>
                        ) : (
                          <input
                            type={
                              question.answer_type === "number"
                                ? "tel"
                                : question.answer_type
                            }
                            value={questionAnswers[question.id]}
                            onChange={(e) =>
                              handleQuestionAnswer(question.id, e.target.value)
                            }
                            className="w-full p-2 sm:p-3 border rounded-md text-sm sm:text-base"
                            required={question.is_required}
                            placeholder={`Enter your ${question.answer_type}`}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}

                <button
                  onClick={handleNextStep}
                  className="w-full py-3 text-lg font-medium rounded-lg bg-[#0B5B5C] text-white hover:bg-green-700"
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 3 && (
        <div className="space-y-4 md:space-y-6">
          <h2 className="text-xl sm:text-2xl font-bold text-[#0B5B5C]">
            Email Verification
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="lg:col-span-2 order-2 lg:order-1">
              <div className="bg-white rounded-lg shadow-md p-2 sm:p-4 mb-4">
                <img
                  src={
                    event?.poster_url || "https://via.placeholder.com/500x300"
                  }
                  alt={event?.name}
                  className="w-full h-auto rounded-lg object-cover"
                />
              </div>
              <h3 className="text-base sm:text-xl font-semibold">
                {event?.name}
              </h3>
              <p className="text-sm sm:text-base text-gray-600 mb-2">
                {event?.category}
              </p>
              <p className="text-sm sm:text-base text-gray-700 mb-4">
                {event?.description}
              </p>
              <div className="flex flex-wrap gap-2 mb-4">
                {event?.tags?.map((tag, index) => (
                  <span
                    key={index}
                    className="text-xs sm:text-sm inline-block bg-gray-200 rounded-full px-2 py-1"
                  >
                    {tag}
                  </span>
                ))}
              </div>
              <div className="mt-8">
                <h3 className="text-lg font-semibold text-[#0B5B5C] mb-4">
                  Organizer
                </h3>
                <div className="bg-white p-4">
                  <div className="flex items-center gap-4 mb-4">
                    <img
                      src={
                        event?.organizations?.logo_url ||
                        "https://via.placeholder.com/60"
                      }
                      alt={event?.organizations?.name}
                      className="w-12 h-12 rounded-full object-cover"
                    />
                    <div>
                      <h4 className="font-semibold">
                        {event?.organizations?.name}
                      </h4>
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-sm text-gray-600 whitespace-pre-line">
                      {event?.organizations?.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Email Verification Card */}
            <div className="order-1 lg:order-2 sticky top-0 lg:top-4">
              <div className="bg-white rounded-lg shadow-md p-6 max-w-md mx-auto">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">
                  Email Verification
                </h2>

                <div className="space-y-4">
                  {/* Email Input Section */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Email Address
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <div className="flex flex-col gap-2">
                      <input
                        type="email"
                        value={orderDetails.email || ""}
                        onChange={(e) => handleEmailChange(e.target.value)}
                        className="w-full p-3 border rounded-md"
                        placeholder="Enter your email"
                        disabled={isEmailVerified}
                        required
                      />
                      <button
                        onClick={handleSendVerificationCode}
                        disabled={
                          isEmailVerified ||
                          !orderDetails.email ||
                          isLoadingSendCode
                        }
                        className="w-full py-3 text-lg font-medium rounded-lg bg-[#0B5B5C] text-white hover:bg-green-700 disabled:bg-gray-300"
                      >
                        {isLoadingSendCode ? (
                          <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mx-auto"></div>
                        ) : isEmailVerified ? (
                          "Verified"
                        ) : isEmailSent ? (
                          "Resend Code"
                        ) : (
                          "Send Code"
                        )}
                      </button>
                    </div>
                  </div>

                  {/* Verification Code Section */}
                  {isEmailSent && !isEmailVerified && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Verification Code
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <input
                        type="text"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        className="w-full p-3 border rounded-md"
                        placeholder="Enter verification code"
                        required
                      />
                    </div>
                  )}

                  {emailError && (
                    <p className="text-red-500 text-sm">{emailError}</p>
                  )}

                  <button
                    onClick={async () => {
                      if (!isEmailVerified) {
                        await handleVerifyCode();
                      }
                      if (isEmailVerified) {
                        handleNextStep();
                      }
                    }}
                    className="w-full py-3 text-lg font-medium rounded-lg bg-[#0B5B5C] text-white hover:bg-green-700 disabled:bg-gray-300"
                    disabled={!isEmailSent || isLoadingVerify}
                  >
                    {isLoadingVerify ? (
                      <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mx-auto"></div>
                    ) : isEmailVerified ? (
                      "Proceed"
                    ) : (
                      "Verify & Proceed"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 4 && (
        <div className="space-y-4 md:space-y-6">
          <h2 className="text-xl sm:text-2xl font-bold text-[#0B5B5C]">
            Complete Purchase
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="lg:col-span-2 order-2 lg:order-1">
              <div className="bg-white rounded-lg shadow-md p-2 sm:p-4 mb-4">
                <img
                  src={
                    event?.poster_url || "https://via.placeholder.com/500x300"
                  }
                  alt={event?.name}
                  className="w-full h-auto rounded-lg object-cover"
                />
              </div>
              <h3 className="text-base sm:text-xl font-semibold">
                {event?.name}
              </h3>
              <p className="text-sm sm:text-base text-gray-600 mb-2">
                {event?.category}
              </p>
              <p className="text-sm sm:text-base text-gray-700 mb-4">
                {event?.description}
              </p>
              <div className="flex flex-wrap gap-2 mb-4">
                {event?.tags?.map((tag, index) => (
                  <span
                    key={index}
                    className="text-xs sm:text-sm inline-block bg-gray-200 rounded-full px-2 py-1"
                  >
                    {tag}
                  </span>
                ))}
              </div>
              <div className="mt-8">
                <h3 className="text-lg font-semibold text-[#0B5B5C] mb-4">
                  Organizer
                </h3>
                <div className="bg-white p-4">
                  <div className="flex items-center gap-4 mb-4">
                    <img
                      src={
                        event?.organizations?.logo_url ||
                        "https://via.placeholder.com/60"
                      }
                      alt={event?.organizations?.name}
                      className="w-12 h-12 rounded-full object-cover"
                    />
                    <div>
                      <h4 className="font-semibold">
                        {event?.organizations?.name}
                      </h4>
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-sm text-gray-600 whitespace-pre-line">
                      {event?.organizations?.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Order Summary Card */}
            <div className="order-1 lg:order-2 sticky top-0 lg:top-4">
              <div className="bg-white rounded-lg shadow-md p-6 max-w-md mx-auto">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">
                  Order Summary
                </h2>

                {/* Ticket Summary */}
                {tickets.map(
                  (ticket) =>
                    ticketCounts[ticket.id] > 0 && (
                      <div key={ticket.id} className="mb-4">
                        <div className="flex justify-between mb-1">
                          <p className="text-sm font-semibold">
                            {ticket.name} x {ticketCounts[ticket.id]}
                          </p>
                          <p>
                            $
                            {(
                              calculateTicketPrice(ticket) *
                              ticketCounts[ticket.id]
                            ).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    )
                )}

                {/* Total Calculations */}
                <div className="border-t pt-4 mt-4">
                  <div className="flex justify-between mb-2">
                    <p>Subtotal</p>
                    <p>${calculateSubtotal().toFixed(2)}</p>
                  </div>
                  <div className="flex justify-between mb-2 text-sm text-gray-600">
                    <p>Service Fee</p>
                    <p>${calculateTotalServiceFees().toFixed(2)}</p>
                  </div>
                  <div className="flex justify-between font-semibold mt-4 pt-4 border-t">
                    <p>Total</p>
                    <p>${calculateTotal().toFixed(2)}</p>
                  </div>
                </div>

                <div className="mt-6">
                  <h3 className="text-base font-semibold text-[#0B5B5C] mb-2">
                    Refund Policy
                  </h3>
                  <p className="text-sm text-red-600 mb-4">
                    {event?.allow_refunds
                      ? "This Ticket Is Refundable"
                      : "This Ticket Is Non-Refundable"}
                  </p>
                </div>

                {/* Payment Section */}
                <div className="mt-6">
                  <h3 className="text-base font-semibold text-[#0B5B5C] mb-2">
                    Payment Processing
                  </h3>
                  <p className="text-sm text-gray-600 mb-4">
                    Payment will be processed via Paynow
                  </p>

                  <button
                    onClick={handleCompletePurchase}
                    className="w-full py-3 text-lg font-medium rounded-lg bg-[#0B5B5C] text-white hover:bg-green-700"
                  >
                    Complete Purchase
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Similar Events section */}
      {event && (
        <div className="mt-8 md:mt-12">
          <SimilarEvents currentEvent={event} />
        </div>
      )}
    </div>
  );
};

export default EventDetail;

import appleStore from "../../assets/images/apple logo.svg";
import googlePlay from "../../assets/images/Play Store.svg";
import front from "../../assets/images/Phone.svg";
import logo from "../../assets/images/Tango Africa logo 1.svg";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className=" text-white ">
      <div className="grid grid-cols-1 md:grid-cols-2  px-4 bg-[#0B5B5C]">
        <div className="grid items-start content-center justify-center gap-3">
          <h1 className="text-[44px] font-bold text-center md:text-left gap-2">
            Don't Miss Out on<br /> the Fun -<br /> Download Now!
          </h1>
          <div className="flex gap-3">
            <a href="https://apps.apple.com/app/tango-africa/id6499582097" target="_blank" rel="noopener noreferrer">
              <button className="bg-[#2F2E2E] text-white p-2 flex items-center gap-2">
                <span className="text-xs flex flex-col items-center">
                  <span>Download on</span>
                  <span className="font-bold">Apple Store</span>
                </span>
                <img src={appleStore} alt="apple store" className="w-5" />
              </button>
            </a>
            <a href="https://play.google.com/store/apps/details?id=zw.co.tango.tango" target="_blank" rel="noopener noreferrer">
              <button className="bg-[#2F2E2E] text-white p-2 flex items-center gap-2">
                <span className="text-xs flex flex-col items-center">
                  <span>Download on</span>
                  <span className="font-bold">Google Play</span>
                </span>
                <img src={googlePlay} alt="google play" className="w-5" />
              </button>
            </a>
          </div>
        </div>
        <div className="hidden md:flex items-center gap-4">
          <img src={front} alt="front" className="w-full md:w-auto" />
        </div>
      </div>

      <div className="bg-black py-8 ">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 max-w-7xl mx-auto px-4">
          <div className="flex flex-col items-center md:items-start">
            <h3 className="font-bold text-[#0B5B5C]">Phone</h3>
            <p>+263 773 018 727 <br/>+263 773 290 803</p>
            <h3 className="font-bold mt-4 text-[#0B5B5C]">Email</h3>
            <p>info@tango.co.zw</p>
          </div>
          <div className="flex flex-col items-center md:items-start">
            <h3 className="font-bold text-[#0B5B5C]">Address</h3>
            <p>
              Fourth Floor, 3 Anchor House, Jason Moyo Avenue, Harare, Zimbabwe
            </p>
          </div>
          <div className="flex flex-col items-center md:items-start">
            <h3 className="font-bold text-[#0B5B5C]">Business Hours</h3>
            <p>0900 - 1700</p>
            <p>Monday - Saturday</p>
          </div>
          <div>
            <h3 className="font-bold text-[#0B5B5C]">Follow us</h3>
            <div className="flex gap-6 mt-4 md:mt-0">
              <a 
                href="https://www.facebook.com/profile.php?id=61561703523510" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FaFacebook size={20} />
              </a>
              <FaSquareXTwitter size={20} />
              <FaTwitter size={20} />
              <FaInstagram size={20} />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black py-4">
        <div className="flex flex-col md:flex-row justify-between items-center max-w-7xl mx-auto px-4">
          <div className="flex flex-col items-center md:items-start">
            <img src={logo} alt="Tango Africa Logo" className="w-24 mb-4" />
          </div>
          <p>© 2025 Tango Africa. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
